.home {
	min-height: 100vh;
	display: flex;
	flex-direction: column;
	align-items: center;
	max-width: var(--containerMaxWidth);
	margin: 0 auto;
	padding: 40px 27px 80px;
	overflow: hidden;

	&-svgLogo {
		margin-bottom: 60px;

		.mainTitle {
			opacity: 0;
		}
	}

	&-servicesList {
		opacity: 0;
		width: 0;
		display: grid;
		grid-template-columns: 38px 1fr 1fr 1fr;
		column-gap: 20px;
		font-size: 10px;
		line-height: 16px;
		padding-bottom: 25px;
		border-bottom: 1px solid var(--black);
		margin-bottom: 55px;

		svg {
			width: 14px;
			height: 10px;
		}

		span {
			display: block;
			white-space: nowrap;
		}
	}

	&-title {
		font-size: 30px;
		line-height: 35px;
		font-weight: 600;
		margin-bottom: 45px;
		opacity: 0;
		max-width: 100%;
		pointer-events: none;
		user-select: none;

		span {
			display: block;

			&:nth-child(3) {
				width: 100%;
			}
		}
	}

	&-servicesListItem,
	&-projectsListItem:first-child {
		opacity: 0;
	}

	&-link {
		display: block;
		width: max-content;
		color: inherit;
		font-size: 14px;
		line-height: 15px;
		padding: 10px 30px;
		border: 1px solid var(--black);
		margin: 0 auto;
	}

	.separator {
		margin-top: 60px;
		margin-bottom: 60px;
	}
}

@media (--media-desktop) {
	.home {
		padding: 120px 0 150px;
		align-items: flex-start;

		&-svgLogo {
			display: none;
		}

		.breadcrumb {
			margin-bottom: 40px;
		}

		&-title {
			font-size: 50px;
			line-height: 60px;
			margin-bottom: 60px;
			min-height: 180px;

			br {
				display: block;
			}
		}

		&-servicesList {
			margin-bottom: 240px;

			svg {
				width: 16px;
				height: 12px;
			}

			span {
				font-size: 12px;
			}
		}

		&-projectsList {
			display: flex;
			flex-direction: column;
			width: 100%;
		}

		.projectCard {
			max-width: 920px;
			margin-left: auto;
			margin-right: auto;
			display: grid;
			grid-template-areas:
				'a a'
				'b b'
				'c c'
				'd e';
			place-items: flex-start flex-start;
			justify-content: space-between;

			&-image {
				margin-bottom: 40px;
				grid-area: a;
			}

			&-category {
				grid-area: b;
				position: relative;
				font-size: 14px;
				margin-bottom: 25px;

				&::after {
					content: '';
					height: 1px;
					width: 100%;
					background-color: var(--darkestGray);
					bottom: -2px;
					display: block;
				}
			}

			&-title {
				grid-area: c;
				font-size: 26px;
				line-height: 30px;
				margin-bottom: 25px;
			}

			&-description {
				grid-area: d;
				text-align: left;
				margin-bottom: 0;
				max-width: 440px;
				font-size: 14px;
				line-height: 24px;
			}

			.home-link {
				grid-area: e;
			}
		}

		.separator {
			margin-top: 100px;
			margin-bottom: 100px;
		}
	}
}
